<template>
  <div class="">
    <v-row class="">
      <v-col class="">
        <h1>Writers</h1>
      </v-col>
      <v-col></v-col>
      <v-col>
        <v-card-title class="text-body-1">
          <v-spacer></v-spacer>
          <span> Online Only</span>
          <v-switch v-model="online" class="ml-4"> </v-switch>
        </v-card-title>
      </v-col>
    </v-row>

    <v-card flat class="mt-0" :color="dark">
      <v-row class="ma-0 ">
        <v-col cols="8" class="py-0">
          <v-tabs background-color="transparent">
            <v-tab key="tab1" to="/dashboard/writers/all">ALl WRITERS</v-tab>
            <v-tab key="tab2" to="/dashboard/writers/my">MY WRITERS</v-tab>
            <!-- <v-tab key="tab3" to="/dashboard/writers/blocked">BLOCKED</v-tab> -->
          </v-tabs>
          <v-divider class="mt-0" color="grey"></v-divider>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-text-field
            solo
            single-line
            placeholder="Search"
            prepend-inner-icon="fas fa-search"
            :background-color="bids"
            class="rounded-lg"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <router-view class="mt-n5"></router-view>
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "writers",
  mixins: [colorMxn],
  data() {
    return {
      online: false
    };
  },
  watch: {
    online(val) {
      const res = val ? val : null;
      this.setIsOnline(res);
    }
  },
  methods: {
    ...mapMutations(['setIsOnline']),
  } 

  
};
</script>

<style scoped>
.btn-border {
  border-radius: 4px;
  color: #fafafd;
  background-color: #232c3b;
  border: 1px solid #47505e;
}
a.v-tab {
  text-decoration: none;
}
</style>
